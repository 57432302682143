'use client';
import { IC_CARTICON } from '@/assets/icons';
import { IM_PAYMENTS_ICONS_V2 } from '@/assets/images';
import Button from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';
import Modal from '@/components/atoms/Modal';
import OrBreak from '@/components/atoms/OrBreak/OrBreak';
import Radio from '@/components/atoms/Radio';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import AcknowledgeCongratulations from '@/components/dynamics/acknowledgeCongratulations/AcknowledgeCongratulations';
import AccountVerificationProcess from '@/components/molecules/AccountVerification/AccountVerificationProcess';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import { API_CART } from '@/services/apis/apiConstrant';
import { prepareOrderData } from '@/services/utils/OrderHelper';
import { newWindowLoad } from '@/services/utils/helper';
import { isEmpty } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { MdInfo, MdOutlineClose, MdWarning } from 'react-icons/md';
import OrderSummaryCheckOut from '../OrderSummary/OrderSummaryCheckOut';
import { OrderSuccess } from '../SuccessModal/OrderSuccess';
import styles from './CheckoutModal.module.scss';
import QuantityDecreasedContent from './QuantityDecreasedContent';

const CheckoutModal = ({
	isOpen,
	onClose,
	carts,
	setIsOpen,
	hasQuantityDecreased,
	setHasQuantityDecreased,
	userType,
	orderLoading,
	isOrderPlaced,
	orderData,
	handleOrder,
	setOrderLoading
}: any) => {
	const router = useRouter();
	const {
		setIsPayementLoading,
		removeCart,
		cartAction,
		paymentSuccessModal,
		setPaymentSuccessModal,
		setPaymentSuccessModalData,
		getCart
	} = useCart();
	const alert = useAlert();
	const { user } = useAuth();
	const [startVerification, setStartVerification] = useState<boolean>(false);
	const [isCodAvailable, setIsCodAvailable] = useState<boolean>(false);
	const [paymentMethod, setPaymentMethod] = useState('online');
	const [showCongratulation, setShowCongratulation] = useState(false);

	//hasQuantityDecreased
	const {
		hasProducts,
		hasUnavailableProducts,
		isDhakaCity,
		isDhakaCityforQty,
		filteredCarts,
		isPrescriptionOrderOnly,
		globalCODAvailable,
		isSoftOrder,
		isMaxCod,
		showCODOptions
		// isPaid
	} = prepareOrderData(orderData, carts);

	const handleOnlinePayment = async () => {
		// paymentMethod === 'online' &&
		if (orderData?.payment_url) {
			newWindowLoad(orderData?.payment_url, orderData?.po_id, router, setIsPayementLoading, alert, {
				paymentSuccessModal,
				setPaymentSuccessModal,
				setPaymentSuccessModalData
			});
			setIsOpen(false);
		}
	};
	const isPaid = orderData?.po_payment_status === 'paid' || isPrescriptionOrderOnly;
	const isUserVerified = user?.u_is_mobile_verified === 1;

	const requiresVerification = isSoftOrder && !isMaxCod && !isUserVerified;
	const isCodEligible = globalCODAvailable || isDhakaCity;

	const isVerificationRequired = requiresVerification && isCodEligible;

	useEffect(() => {
		setIsCodAvailable(isVerificationRequired);
	}, [isVerificationRequired]);

	const handleMakeRegularOrder = async (id) => {
		const { status, data } = await AroggaAPI.post(API_CART.SOFT_TO_REGULAR_ORDER(id));
		if (status === 'success') {
			setShowCongratulation(true);
		}
	};

	const renderScreen = (screen) => {
		switch (screen) {
			case 'prescriptionOnly':
				return (
					<Congratulations
						isSoftOrder={isSoftOrder}
						isPrescriptionOrderOnly={isPrescriptionOrderOnly}
						isPaid={isPaid}
						orderData={orderData}
						onClose={onClose}
					/>
				);
			case 'paymentOptions':
				return (
					<PaymentOptions
						showCODOptions={showCODOptions}
						paymentMethod={paymentMethod}
						setPaymentMethod={setPaymentMethod}
						isCodAvailable={isCodAvailable}
						orderData={orderData}
						carts={carts}
						isVerificationRequired={isVerificationRequired}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			{hasQuantityDecreased ? (
				<QuantityDecreasedContent
					hasQuantityDecreased={hasQuantityDecreased}
					setIsOpen={setIsOpen}
					onClose={onClose}
					setPaymentMethod={setPaymentMethod}
					hasProducts={hasProducts}
					hasUnavailableProducts={hasUnavailableProducts}
					filteredCarts={filteredCarts}
					carts={carts}
					userType={userType}
					isDhakaCityForQty={isDhakaCityforQty}
					cartAction={cartAction}
					removeCart={removeCart}
					setHasQuantityDecreased={setHasQuantityDecreased}
					router={router}
					handleOrder={handleOrder}
				/>
			) : (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						onClose();
						setPaymentMethod(null);
					}}
					footer={null}
					{...(!startVerification && {
						footer: (
							<ModalFooter
								isOrderPlaced={isOrderPlaced}
								showCongratulation={showCongratulation}
								isSoftOrder={isSoftOrder}
								isPrescriptionOrderOnly={isPrescriptionOrderOnly}
								isPaid={isPaid}
								orderLoading={orderLoading}
								handleOnlinePayment={handleOnlinePayment}
								orderData={orderData}
								router={router}
								setIsOpen={setIsOpen}
								checkIsVerificationRequired={isVerificationRequired && paymentMethod === 'cod'}
								setStartVerification={setStartVerification}
							/>
						)
					})}
					width={480}
					maskClosable={false}
					title={orderLoading || isPaid || startVerification || showCongratulation ? '' : 'Payment'}
					bodyStyle={{
						borderRadius: '15px',
						backgroundColor: startVerification ? 'transparent' : '#F7FAFC',
						padding: !isPaid ? '10px 20px' : '0px',
						maxHeight: 'calc(100vh - 200px)',
						overflowY: 'auto',
						position: 'relative',
						...(startVerification && { padding: '0px' }),
						...(showCongratulation && { padding: '0px' })
					}}>
					{startVerification && (
						<AccountVerificationProcess
							isOpen={startVerification}
							onClose={() => {
								setStartVerification(false);
							}}
							onPayOnline={() => {
								setPaymentMethod('online');
								setStartVerification(false);
							}}
							data={{
								type: 'mobile',
								identifier: user.u_mobile
							}}
							from='checkout'
							onLoginComplete={() => {
								setIsOpen(false);
							}}
							onComplete={async (fn) => {
								setOrderLoading(true);
								if (fn) {
									const res = await fn(false);
									if (res) {
										setStartVerification(false);
										handleMakeRegularOrder(orderData.id);
										showCongratulation && setShowCongratulation(true);
									}
								}
								setOrderLoading(false);
							}}
						/>
					)}
					{!startVerification && (
						<>
							{orderLoading ? (
								<SpinnerWrapper loading={orderLoading}>
									<div
										style={{
											minHeight: '200px',
											width: '100%'
										}}></div>
								</SpinnerWrapper>
							) : (
								<>
									{showCongratulation ? (
										<OrderSuccess
											data={orderData}
											onClose={() => {
												setShowCongratulation(false);
												setIsOpen(false);
												getCart();
											}}
										/>
									) : (
										<>
											{renderScreen('prescriptionOnly')}
											{!isPrescriptionOrderOnly && renderScreen('paymentOptions')}
											{!isPaid && orderData && !isPrescriptionOrderOnly && (
												<OrderSummaryCheckOut data={orderData} />
											)}
										</>
									)}
								</>
							)}
						</>
					)}
				</Modal>
			)}
		</>
	);
};

export default CheckoutModal;

const ModalFooter = ({
	isOrderPlaced,
	isSoftOrder,
	isPrescriptionOrderOnly,
	isPaid,
	orderLoading,
	handleOnlinePayment,
	orderData,
	router,
	setIsOpen,
	checkIsVerificationRequired,
	setStartVerification,
	showCongratulation
}: any) => {
	if (checkIsVerificationRequired) {
		return (
			<div className={styles.stickyFooter}>
				<div className='d-flex gap-10 flex-column'>
					<Button
						type='primary'
						className='w-full'
						onClick={() => {
							setStartVerification(true);
						}}>
						Verify Number
					</Button>
				</div>
			</div>
		);
	}
	return isOrderPlaced || showCongratulation ? (
		<div className={styles.stickyFooter}>
			<div className='d-flex gap-10 flex-column'>
				{isPaid ? (
					<Button
						type='primary'
						className='w-full'
						onClick={() => {
							router.push('/');
							setIsOpen(false);
						}}>
						<IC_CARTICON />
						<span className='ml-5'>Start A New Order</span>
					</Button>
				) : (
					<Button
						disabled={orderLoading}
						type='primary'
						className='w-full'
						onClick={() => {
							handleOnlinePayment();
						}}>
						Pay Online: ৳{orderData?.po_payable_total}
					</Button>
				)}

				{showCongratulation && (
					<Button
						disabled={orderLoading}
						type='primary-outline'
						className='w-full'
						onClick={() => {
							router.push(`/account/orders/${orderData?.po_id}`);
							setIsOpen(false);
						}}>
						Track Order
					</Button>
				)}
				{!isSoftOrder && !isPrescriptionOrderOnly && !showCongratulation && (
					<Button
						disabled={orderLoading}
						type='primary-outline'
						className='w-full'
						onClick={() => {
							router.push(`/account/orders/${orderData?.po_id}`);
							setIsOpen(false);
						}}>
						Track Order
					</Button>
				)}
			</div>
		</div>
	) : null;
};

const ModalCloseButton = ({ onClose }) => {
	const router = useRouter();

	return (
		<div
			style={{
				position: 'absolute',
				right: 0,
				top: 0,
				padding: '10px',
				zIndex: 10,
				color: '#98A2B3'
			}}>
			<div
				style={{ cursor: 'pointer' }}
				onClick={() => {
					onClose();
					router.refresh();
				}}>
				<MdOutlineClose size={24} className='hover-text-error' />
			</div>
		</div>
	);
};

const Congratulations = ({ isSoftOrder, isPrescriptionOrderOnly, isPaid, orderData, onClose }) => (
	<>
		{(!isSoftOrder || isPrescriptionOrderOnly) && (
			<Card
				bordered={false}
				size='small'
				bodyStyle={
					isPaid
						? {
								padding: '16px',
								margin: '0px 0px 0px 0px'
							}
						: {}
				}>
				{isPrescriptionOrderOnly && <ModalCloseButton onClose={onClose} />}
				<AcknowledgeCongratulations
					step={isPaid ? 2 : 1}
					showStep={isPrescriptionOrderOnly ? false : true}
					data={orderData}
					isCollapsible={!isPaid && !isPrescriptionOrderOnly}
					message={
						isPaid ? (
							isPrescriptionOrderOnly ? (
								orderData?.responseMessage
							) : (
								<>Order placed and paid successfully.</>
							)
						) : (
							<>
								Order placed successfully by
								<strong style={{ color: '#1F2937' }}> &quot;Cash On Delivery&quot;.</strong>
							</>
						)
					}
				/>
			</Card>
		)}
	</>
);

const CODRestrictionMessage = ({
	isDhakaCity,
	isMaxCod,
	globalCODAvailable,
	orderData,
	isRequiredVerification,
	paymentMethod,
	hidden
}) => {
	if (hidden) return null;

	let codMessage = null;
	let Icon = <MdInfo style={{ color: '#2970FF' }} />;
	let bgColor = 'bg-info';

	if (isRequiredVerification && paymentMethod === 'cod') {
		// Handle account verification case
		Icon = <MdWarning style={{ color: '#FFA500' }} />;
		bgColor = 'bg-orange_bg';
		codMessage = (
			<span className='text-grey700 fw-400 text-14'>
				Please verify <strong>account</strong> phone number for <strong>one time</strong> to get cash on delivery.
			</span>
		);
	} else if (isRequiredVerification) {
	} else if (isDhakaCity && isMaxCod) {
		codMessage = (
			<span className='text-grey700 fw-500 text-14'>
				<strong>Cash on Delivery</strong> is not available over <strong>৳{orderData?.maxCod}</strong>.
			</span>
		);
	} else if (isMaxCod) {
		codMessage = (
			<span className='text-grey700 fw-500 text-14'>
				<strong>Cash on Delivery</strong> is not available over <strong>৳{orderData?.maxCod}</strong>.
			</span>
		);
	} else if (isDhakaCity && isMaxCod) {
		codMessage = (
			<span className='text-grey700 fw-500 text-14'>
				<strong>Cash on Delivery</strong> is not available over <strong>৳{orderData?.maxCod}</strong>.
			</span>
		);
	} else if (!isDhakaCity && globalCODAvailable && isMaxCod) {
		codMessage = (
			<span className='text-grey700 fw-500 text-14'>
				<strong>Cash on Delivery</strong> is not available over <strong>৳{orderData?.maxCod}</strong>.
			</span>
		);
	} else if (!isDhakaCity && (!globalCODAvailable || !isMaxCod)) {
		codMessage = (
			<span className='text-grey700 fw-500 text-14'>
				<strong>Cash on Delivery</strong> is not available for <strong>Outside Dhaka.</strong>
			</span>
		);
	} else if (isDhakaCity && !isMaxCod && !globalCODAvailable) {
		codMessage = (
			<span className='text-grey700 fw-500 text-14'>
				<strong>Cash on Delivery</strong> is not available for <strong>Outside Dhaka.</strong>
			</span>
		);
	}

	// Return early if no message is set
	if (!codMessage) return null;

	return (
		<div>
			<p className={`${bgColor} p-6 rounded-4 d-flex gap-6 w-full items-center`}>
				{Icon}
				{codMessage}
			</p>
		</div>
	);
};

const Timer = ({ time = 30, po_created_at, redirectOff, onlyTime }: any) => {
	const [remainingTime, setRemainingTime] = useState<number>(time * 60);
	const router = useRouter();

	useEffect(() => {
		const orderCreatedAt = new Date(po_created_at.replace(' ', 'T'));

		const now = getTimeForTimezone('Asia/Dhaka');
		const elapsedTime = Math.floor((now - orderCreatedAt.getTime()) / 1000);

		const remaining = time * 60 - elapsedTime;
		setRemainingTime(remaining > 0 ? remaining : 0);

		const interval = setInterval(() => {
			setRemainingTime((prevTime) => {
				if (prevTime > 0) {
					return prevTime - 1;
				} else {
					!redirectOff && router.push('/');
					return 0;
				}
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [time, po_created_at, redirectOff, router]);

	const formatTime = (seconds: number) => {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const secs = seconds % 60;

		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = secs.toString().padStart(2, '0');

		if (hours > 0) {
			return `${formattedHours}:${formattedMinutes}:${formattedSeconds} hours`;
		} else if (minutes > 0) {
			return `${formattedMinutes}:${formattedSeconds} minutes`;
		} else {
			return `${formattedSeconds} seconds`;
		}
	};
	const timerColor = remainingTime <= 5 * 60 ? 'red' : 'green';

	if (onlyTime) {
		return <span style={{ color: timerColor }}>{formatTime(remainingTime)}</span>;
	}
	return (
		<>
			<span style={{ color: timerColor }}>{formatTime(remainingTime)}</span>
		</>
	);
};
const getTimeForTimezone = (timezone: string): number => {
	const now = new Date();
	const utcTimestamp = now.getTime(); // Current UTC timestamp in milliseconds
	const timezoneOffset = new Date(now.toLocaleString('en-US', { timeZone: timezone })).getTime() - utcTimestamp;

	return utcTimestamp + timezoneOffset;
};

const PaymentOptions = ({
	paymentMethod,
	setPaymentMethod,
	isCodAvailable,
	orderData,
	carts,
	showCODOptions: showCODOptionsShow,
	isVerificationRequired
}) => {
	const [showCODOptions, setShowCODOptions] = useState(showCODOptionsShow);
	const alert = useAlert();
	const { user } = useAuth();
	const { isDhakaCity, globalCODAvailable, isSoftOrder, isMaxCod } = prepareOrderData(orderData, carts);
	const showPamynetOption = !isEmpty(orderData?.payment_url);
	useEffect(() => {
		if (isVerificationRequired) {
			setShowCODOptions(true);
		}
	}, [isVerificationRequired]);
	console.log('showCODOptions', showCODOptions);
	return (
		<Card
			size='small'
			bordered={false}
			title={
				!isSoftOrder ? (
					'Do you want to pay now?'
				) : (
					<p className='fw-600'>
						Confirm payment within{' '}
						<strong style={{ color: 'green' }}>
							<Timer time={orderData?.po_payment_threshold} po_created_at={orderData?.po_created_at} />
						</strong>{' '}
						minutes to complete order
					</p>
				)
			}
			bodyStyle={{
				padding: '20px'
			}}
			headerStyle={{
				borderRadius: '12px 12px 0px 0px'
			}}>
			{showPamynetOption ? (
				<div className='d-flex flex-column gap-20'>
					<Radio.Group
						name='address'
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: 20
						}}
						value={paymentMethod}
						defaultValue={paymentMethod}
						onChange={(e) => {
							const selectedValue = e.target.value;
							if (!isCodAvailable && selectedValue === 'cod') {
								alert.warning({
									title: 'Warning',
									message: 'COD is not available'
								});
								setPaymentMethod('online');
							} else {
								setPaymentMethod(e.target.value);
							}
						}}>
						<Radio
							value='online'
							label='Online Payment'
							onClick={() => {
								setPaymentMethod('online');
							}}>
							<span className='text-16 fw-500'>Pay Online</span>
						</Radio>

						<div style={{ display: 'flex' }}>
							<Image src={IM_PAYMENTS_ICONS_V2.src} width={408} height={120} alt='payment icons' unoptimized />
						</div>
						<OrBreak hidden={!showCODOptions} />
						<Radio
							// hidden for now
							hidden={!showCODOptions}
							value='cod'
							disabled={!isCodAvailable}
							onClick={() => {
								const selectedValue = 'cod';
								if (!isCodAvailable && selectedValue === 'cod') {
									alert.warning({
										title: 'Warning',
										message: 'COD is not available'
									});
									setPaymentMethod('online');
								} else {
									setPaymentMethod('cod');
								}
							}}
							style={
								!isCodAvailable
									? {
											cursor: 'not-allowed'
										}
									: {}
							}>
							<span
								className='text-grey900 fw-700'
								style={isCodAvailable ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
								onClick={() => {
									if (!isCodAvailable) {
										alert.warning({
											title: 'Warning',
											message: 'COD is not available'
										});
									}
								}}>
								{isCodAvailable ? (
									'Cash on delivery (COD)'
								) : (
									<>
										<div className='text-grey500'>Cash on delivery (COD)</div>
									</>
								)}
							</span>
						</Radio>

						<CODRestrictionMessage
							hidden={!showCODOptions}
							isDhakaCity={isDhakaCity}
							isMaxCod={isMaxCod}
							globalCODAvailable={globalCODAvailable}
							orderData={orderData}
							isRequiredVerification={isVerificationRequired}
							paymentMethod={paymentMethod}
						/>
					</Radio.Group>
				</div>
			) : (
				<div className='d-flex flex-column gap-20 fw-500'>
					{user.u_cash_balance > 0 ? (
						<span>Your Payment will be deducted from your Arogga Cash Balance</span>
					) : (
						<div className='text-14 text-grey500'>
							Our customer service will call you to confirm your order and payment amount
						</div>
					)}
				</div>
			)}
		</Card>
	);
};
